import React from 'react';
import moment from 'moment';
import {Snackbar} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {useTranslation} from 'react-i18next';
import {Message} from '../../containers/Message/types';

interface MessageProps {
  message: Message,
  onClose: () => void,
}

const MessageDisplay: (props: MessageProps) => React.ReactElement = ({message, onClose}) => {
  const {t} = useTranslation();
  const autoHideDuration = getAutoHideDuration(message);

  return (
    <Snackbar open={autoHideDuration > 0} autoHideDuration={autoHideDuration} onClose={onClose}>
      <Alert variant="filled" {...message} onClose={onClose}>
        {t(message.message)}
      </Alert>
    </Snackbar>
  );
};

export const getAutoHideDuration: (messages: Message) => number = (message) => {
  let duration = 0;
  const currentTime = moment().unix() * 1000;
  const currentDuration = message.validUntil - currentTime;
  duration = currentDuration > duration ? currentDuration : duration;
  return duration;
};

export default MessageDisplay;
