import {selector} from 'recoil';
import {loginTokenSate} from './atoms';

export const isLoggedInSelect = selector({
  key: 'isLoggedInSelect',
  get: ({get}) => {
    const authToken = get(loginTokenSate);
    return authToken.length > 0;
  },
});
