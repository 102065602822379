export const SHOOTER_DELETE_ENTRY = 'shooter.delete_entry';
export const SHOOTER_DELETE_ENTRY_SELF = 'shooter.delete_own_entry';
export const SHOOTER_DELETE_OWN_ENTRY = 'shooter.delete_own_entry';
export const SHOOTER_ADD_ENTRY_COMMENT = 'shooter.add_entrycomment';
export const SHOOTER_ADD_SHOOTER = 'shooter.add_shooter';
export const SHOOTER_DELETE_DISCIPLINE = 'shooter.delete_discipline';
export const AUTH_ADD_USER = 'auth.add_user';
export const SHOOTER_VIEW_OWN_SHOOTER = 'shooter.view_own_shooter';
export const SHOOTER_DELETE_OWN_SHOOTER = 'shooter.delete_own_shooter';
export const SHOOTER_VIEW_DISCIPLINE = 'shooter.view_discipline';
export const SHOOTER_DELETE_ANY_SHOOTER = 'shooter.delete_any_shooter';
export const AUTH_DELETE_USER = 'auth.delete_user';
export const SHOOTER_CHANGE_DISCIPLINE = 'shooter.change_discipline';
export const SHOOTER_VIEW_ENTRY = 'shooter.view_entry';
export const SHOOTER_VIEW_ENTRY_SELF = 'shooter.view_own_entry';
export const SHOOTER_VIEW_ENTRY_COMMENT = 'shooter.view_entrycomment';
export const SHOOTER_ADD_ENTRY = 'shooter.add_entry';
export const SHOOTER_ADD_ENTRY_SELF = 'shooter.add_own_entry';
export const AUTH_CHANGE_USER = 'auth.change_user';
export const AUTH_VIEW_USER = 'auth.view_user';
export const SHOOTER_CHANGE_OWN_SHOOTER = 'shooter.change_own_shooter';
export const SHOOTER_CHANGE_ENTRY_COMMENT = 'shooter.change_entrycomment';
export const SHOOTER_CHANGE_OWN_ENTRY = 'shooter.change_own_entry';
export const SHOOTER_ADD_DISCIPLINE= 'shooter.add_discipline';
export const SHOOTER_DELETE_ENTRY_COMMENT= 'shooter.delete_entrycomment';
export const SHOOTER_CHANGE_SHOOTER = 'shooter.change_shooter';
export const SHOOTER_CHANGE_SHOOTER_SELF = 'shooter.change_own_shooter';
export const SHOOTER_DELETE_SHOOTER = 'shooter.delete_shooter';
export const SHOOTER_DELETE_SHOOTER_SELF = 'shooter.delete_own_shooter';
export const SHOOTER_CHANGE_ENTRY = 'shooter.change_entry';
export const SHOOTER_CHANGE_ENTRY_SELF = 'shooter.change_own_entry';
export const SHOOTER_VIEW_OWN_ENTRY= 'shooter.view_own_entry';
export const SHOOTER_VIEW_SHOOTER = 'shooter.view_shooter';
export const SHOOTER_VIEW_SHOOTER_SELF = 'shooter.view_own_shooter';
