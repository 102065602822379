import {atom, selector} from 'recoil';
import {FullShooter, ShooterModel} from '../../types';
import {userMapSelector} from '../User/atoms';

export const shooterState = atom<ShooterModel[]>({
  key: 'shooterList',
  default: [],
});

export const shooterMapSelector = selector<Map<number, ShooterModel>>({
  key: 'shooterMapList',
  get: ({get}) => {
    const shooters = get(shooterState);
    const shooterMap = new Map<number, ShooterModel>();
    for (const shooter of shooters) {
      shooterMap.set(shooter.id, shooter);
    }
    return shooterMap;
  }
});

export const fullShooterSelector = selector<Map<number, FullShooter>>({
  key: 'fullShooterSelector',
  get: ({get}) => {
    const users =  get(userMapSelector);
    const shooters = get(shooterState);
    const fullMap = new Map<number, FullShooter>();
    for (const shooter of shooters) {
      const user = users.get(shooter.user);
      if (typeof user !== 'undefined') {
        const fullShooter: FullShooter = {
          ...shooter,
          user: user,
        };
        fullMap.set(shooter.id, fullShooter);
      }
    }
    return fullMap;
  }
});
