import {DisciplineModel} from '../../types';
import {SetterOrUpdater} from 'recoil';
import {basicAuthorizedJsonGetRequest} from '../../helper';

// Fetch the disciplines and save it to the state.
export const updateDisciplineState: (accessToken: string, setOwnDisciplines: SetterOrUpdater<DisciplineModel[]>) => void = async (accessToken, setOwnDisciplines) => {
  const response = await basicAuthorizedJsonGetRequest('/disciplines/', accessToken);
  // TODO: Handle errors.
  if (response.ok) {
    const disciplines: DisciplineModel[] = await response.json();
    setOwnDisciplines(disciplines);
  }
};
