import {EntryModel, EntryModelWithShooter} from '../../types';
import {SetterOrUpdater} from 'recoil';
import {
  authorizedJsonPostRequest,
  basicAuthorizedDeleteRequest,
  basicAuthorizedJsonGetRequest,
  basicAuthorizedJsonPutRequest
} from '../../helper';

// Fetch the entries of the logged in User and save it to the state.
export const updateOwnEntries: (accessToken: string, setOwnEntries: SetterOrUpdater<EntryModel[]>) => void = async (accessToken, setOwnEntries) => {
  const response = await basicAuthorizedJsonGetRequest('/entries/me/', accessToken);
  // TODO: Handle errors.
  if (response.ok) {
    const entries: EntryModel[] = await response.json();
    setOwnEntries(entries);
  }
};

// Creates the given Entry.
export const createNewEntry: (accessToken: string, entry: EntryModel) => Promise<EntryModel|false> = async (accessToken, entry) => {
  const response = await authorizedJsonPostRequest('/entries/me/', accessToken, JSON.stringify(entry));
  // TODO: Handle errors.
  if (response.ok) {
    return await response.json();
  }
  return false;
};

// Updates the given Entry.
export const updateOwnEntry: (accessToken: string, entry: EntryModel) => Promise<EntryModel|false> = async (accessToken, entry) => {
  const response = await basicAuthorizedJsonPutRequest(`/entries/me/${entry.id}/`, accessToken, JSON.stringify(entry));
  // TODO: Handle errors.
  if (response.ok) {
    return await response.json();
  }
  return false;
};

// Deletes an entry.
export const deleteOwnEntry: (accessToken: string, entryId: number) => void = async (accessToken, entryId) => {
  await basicAuthorizedDeleteRequest(`/entries/me/${entryId}`, accessToken);
  // TODO: Handle errors.
};

export const getAllEntries: (accessToken: string) => Promise<EntryModelWithShooter[]> = async (accessToken) => {
  const response = await basicAuthorizedJsonGetRequest('/entries/', accessToken);
  const entries: EntryModelWithShooter[] = await response.json();
  return entries;
};
