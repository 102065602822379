import React from 'react';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  makeStyles,
  Theme
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import EntryEditForm from '../../../containers/Entry/EntryEditForm';

interface EntryEditDialogComponentProps extends DialogProps {
  onSave: () => void;
  onClose: () => void,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionWrapper: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    action: {
      marginLeft: theme.spacing(1),
    }
  })
);

const EntryEditDialogComponent: (props: EntryEditDialogComponentProps) => JSX.Element = (props) => {
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <Dialog {...props}>
      <DialogTitle>{t('entry_edit.dialog.title')}</DialogTitle>
      <DialogContent>
        <EntryEditForm />
      </DialogContent>
      <DialogActions className={classes.actionWrapper}>
        <Button data-testid="entry-create-submit-button" color="secondary" variant="outlined" onClick={props.onSave}>{t('form.save')}</Button>
        <Button data-testid="entry-create-close-button" color="secondary" className={classes.action} onClick={props.onClose}>{t('form.close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EntryEditDialogComponent;
