import {atom, selector} from 'recoil';
import {UserModel} from '../../types';

const emptyUserObject: UserModel = {
  email: '',
  id: 0,
  first_name: '',
  last_name: '',
};

export const ownUserState = atom<UserModel>({
  key: 'ownUser',
  default: emptyUserObject,
});

export const userListState = atom<UserModel[]>({
  key: 'userList',
  default: [],
});

export const userMapSelector = selector({
  key: 'userMapSelector',
  get: ({get}) => {
    const users = get(userListState);
    const userMap = new Map<number, UserModel>();
    for (const user of users) {
      userMap.set(user.id, user);
    }
    return userMap;
  },
});
