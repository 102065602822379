import React, {ReactElement} from 'react';
import {DialogProps} from '@material-ui/core';
import {useRecoilState, useRecoilValue} from 'recoil';
import {createEntryDraftState, ownEntriesState} from './atoms';
import {EntryModel} from '../../types';
import {loginTokenSate} from '../Login/atoms';
import {createNewEntry, updateOwnEntries} from './requests';
import EntryCreateDialogComponent from '../../components/Entry/EntryCreateDialogComponent';

export interface EntryCreateDialogProps extends DialogProps {
  onClose: () => void,
}

const EntryCreateDialog: (props: EntryCreateDialogProps) => ReactElement = (props) => {
  const [, setOwnEntries] = useRecoilState(ownEntriesState);
  const entryDraft: EntryModel = useRecoilValue(createEntryDraftState);
  const accessToken: string = useRecoilValue(loginTokenSate);

  const saveEntry: () => void = async () => {
    const result = await createNewEntry(accessToken, entryDraft);
    if (result !== false) {
      updateOwnEntries(accessToken, setOwnEntries);
      props.onClose();
    }
  };

  return <EntryCreateDialogComponent {...props} onSave={saveEntry} />;
};

export default EntryCreateDialog;
