import {buildAuthorizationHeader} from './containers/Login/utils';
import {EntryModel} from './types';

export const basicAuthorizedJsonGetRequest: (urlPath: string, accessToken: string) => Promise<Response> = (urlPath, accessToken) => {
  return fetch(`${process.env.REACT_APP_API_BASE_PATH}${urlPath}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: buildAuthorizationHeader(accessToken),
    },
  });
};

export const basicAuthorizedJsonPutRequest: (urlPath: string, accessToken: string, data: string) => Promise<Response> = (urlPath, accessToken, data) => {
  return fetch(`${process.env.REACT_APP_API_BASE_PATH}${urlPath}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: buildAuthorizationHeader(accessToken),
    },
    body: data,
  });
};

export const authorizedJsonPostRequest: (urlPath: string, accessToken: string, json: string) => Promise<Response> = (urlPath, accessToken, json) => {
  return fetch(`${process.env.REACT_APP_API_BASE_PATH}${urlPath}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: buildAuthorizationHeader(accessToken),
    },
    body: json,
  });
};

export const basicAuthorizedDeleteRequest: (urlPath: string, accessToken: string) => Promise<Response> = (urlPath, accessToken) => {
  return fetch(`${process.env.REACT_APP_API_BASE_PATH}${urlPath}`, {
    method: 'DELETE',
    headers: {
      Authorization: buildAuthorizationHeader(accessToken),
    },
  });
};

export const emptyEntryModel: EntryModel = {
  id: 0,
  duration: 0,
  competition_name: '',
  trainer: '',
  description: '',
  is_competition: false,
  series: '',
  discipline: 0,
  date: '',
};
