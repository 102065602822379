import React, {useEffect} from 'react';
import {useRecoilState} from 'recoil';
import {messageState} from './atoms';
import MessageDisplay from '../../components/Message';
import {Message} from './types';
import moment from 'moment';

const removeOldMessages: (messages: Message[], setMessages: React.Dispatch<React.SetStateAction<Message[]>>) => boolean = (messages, setMessages) => {
  const newMessages: Message[] = [];
  const currentTime = moment().unix() * 1000;
  messages.forEach((message) => {
    if (currentTime < message.validUntil) {
      newMessages.push(message);
    }
  });
  if (newMessages.length !== messages.length) {
    setMessages(newMessages);
    return true;
  }
  return false;
};

const Messages: () => React.ReactElement = () => {
  const [messages, setMessages] = useRecoilState(messageState);

  useEffect(() => {
    removeOldMessages(messages, setMessages);
  }, [messages, setMessages]);

  if (messages.length === 0) {
    return <></>;
  }

  return (
    <>
      {messages.map((message) => (
        <MessageDisplay message={message} onClose={() => removeOldMessages(messages, setMessages)} key={`message-${message.severity}-${message.validUntil}`}/>
      ))};
    </>
  );
};

export default Messages;
