import {useEffect} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {ownUserState} from '../User/atoms';
import {ownPermissions} from './atoms';
import {loginTokenSate} from '../Login/atoms';
import {getOwnPermissions} from './request';

const Permission: () => null = () => {
  const user = useRecoilValue(ownUserState);
  const accessToken = useRecoilValue(loginTokenSate);
  const [, setPermissions] = useRecoilState(ownPermissions);

  useEffect(() => {
    if (accessToken.length === 0) {
      return;
    }
    (async () => {
      const permissions = await getOwnPermissions(accessToken);
      setPermissions(permissions);
    })();
  }, [user.id, setPermissions, accessToken]);

  return null;
};

export default Permission;
