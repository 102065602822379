import React, {lazy, ReactElement, Suspense, useEffect} from 'react';
import {EntryModel} from '../../types';
import {useRecoilState, useRecoilValue} from 'recoil';
import {disciplineState} from '../Discipline/atoms';
import {updateDisciplineState} from '../Discipline/requests';
import {loginTokenSate} from '../Login/atoms';
import {createEntryDraftState} from './atoms';
import OverlayIndicator from '../../components/LoadingIndicator/OverlayIndicator';

const EntryForm = lazy(() =>  import('../../components/Entry/EntryForm'));

const EntryCreateForm: () => ReactElement = () => {
  const [entryValues, setEntryValues] = useRecoilState<EntryModel>(createEntryDraftState);

  const [disciplines, setDisciplines] = useRecoilState(disciplineState);
  const accessToken = useRecoilValue(loginTokenSate);

  // Update the disciplines if the component mounts.
  useEffect(() => {
    if (accessToken && accessToken.length > 0) {
      updateDisciplineState(accessToken, setDisciplines);
    }
  }, [accessToken, setDisciplines]);

  return (
    <Suspense fallback={OverlayIndicator}>
      <EntryForm entry={entryValues} setEntryValues={setEntryValues} disciplines={disciplines} />
    </Suspense>
  );
};

export default EntryCreateForm;
