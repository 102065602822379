import React, {ReactChildren, ReactElement, useEffect, useState} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {Dialog, DialogContent, DialogTitle} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {loginTokenSate} from './atoms';
import {isLoggedInSelect} from './selectors';
import {useLocalstorageState} from 'rooks';
import LoginForm from '../../components/LoginForm';
import {messageState} from '../Message/atoms';
import moment from 'moment';
import {Message} from '../Message/types';
import {ownUserState} from '../User/atoms';
import {useMatomo} from '@datapunt/matomo-tracker-react';

export interface AuthenticatedContentContainerProps {
  children: ReactChildren | ReactElement,
}

interface AccessTokenRefreshResponse {
  access: string,
}

interface TokenResponse extends AccessTokenRefreshResponse {
  refresh: string,
}

const AuthenticatedContentContainer: React.FC<AuthenticatedContentContainerProps> = (props) => {
  const [, setAccessToken] = useRecoilState(loginTokenSate);
  const [refreshToken, setRefreshToken, removeRefreshToken] = useLocalstorageState('refresh-token', '');
  const [messages, setMessages] = useRecoilState(messageState);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const isLoggedIn = useRecoilValue(isLoggedInSelect);
  const user = useRecoilValue(ownUserState);
  const {t} = useTranslation();
  const {pushInstruction} = useMatomo();

  useEffect(() => {
    if (user.id !== 0) {
      pushInstruction('setUserId', user.id);
    }
  }, [user]);

  const refreshAccessToken: (token: string) => void = async (token) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_PATH}/auth/token/refresh/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        refresh: token,
      }),
    });
    if (response.ok) {
      const body: AccessTokenRefreshResponse = await response.json();
      setAccessToken(body.access);
    } else {
      removeRefreshToken();
    }
  };

  useEffect(() => {
    if (refreshToken.length > 0) {
      refreshAccessToken(refreshToken);
    }
  }, [refreshToken]);

  const handleFormSubmit: () => void = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_PATH}/auth/token/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        password,
      }),
    });
    if (!response.ok) {
      const newMessages: Message[] = [...messages];
      const validUntil = moment().unix() * 1000 + 6000;
      newMessages.push({
        validUntil,
        severity: 'error',
        message: t('errors.login.fail'),
      });
      setMessages(newMessages);
    }
    // TODO: Handle error (wrong credentials).
    const responseBody: TokenResponse = await response.json();
    setAccessToken(responseBody.access || '');
    setRefreshToken(responseBody.refresh || refreshToken);
  };

  return (
    <>
      {props.children}
      <Dialog open={!isLoggedIn} maxWidth="xs">
        <DialogTitle id="login-dialog-title">{t('loginForm.title')}</DialogTitle>
        <DialogContent>
          <LoginForm username={username} password={password} onSubmit={handleFormSubmit} setUsername={setUsername} setPassword={setPassword} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AuthenticatedContentContainer;
