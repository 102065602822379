import React, {lazy, ReactElement, Suspense, useEffect} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {disciplineState} from '../Discipline/atoms';
import {updateDisciplineState} from '../Discipline/requests';
import {loginTokenSate} from '../Login/atoms';
import {currentEditEntryState} from './atoms';
import {EntryModel} from '../../types';
import OverlayIndicator from '../../components/LoadingIndicator/OverlayIndicator';

const EntryForm = lazy(() => import('../../components/Entry/EntryForm'));

const emptyEntry: EntryModel = {
  id: 0,
  date: '',
  description: '',
  is_competition: false,
  series: '',
  discipline: 0,
  competition_name: '',
  duration: 0,
  trainer: '',
};

const EntryEditForm: () => ReactElement = () => {
  const [entryValues, setEntryValues] = useRecoilState(currentEditEntryState);
  const [disciplines, setDisciplines] = useRecoilState(disciplineState);
  const accessToken = useRecoilValue(loginTokenSate);

  // Update the disciplines if the component mounts.
  useEffect(() => {
    if (accessToken && accessToken.length > 0) {
      updateDisciplineState(accessToken, setDisciplines);
    }
  }, [accessToken, setDisciplines]);

  return (
    <Suspense fallback={OverlayIndicator}>
      <EntryForm entry={entryValues ?? emptyEntry} setEntryValues={setEntryValues} disciplines={disciplines}/>
    </Suspense>
  );
};

export default EntryEditForm;
