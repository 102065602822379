import React, {ReactElement} from 'react';
import {Backdrop, CircularProgress, createStyles, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    backdrop: {
      zIndex: 5000,
      backgroundColor: '#000B',
    },
  })
);

const OverlayIndicator: () => ReactElement = () => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default OverlayIndicator;
