import React, {ReactElement} from 'react';
import MomentUtils from '@date-io/moment';
import {KeyboardDatePicker, KeyboardDatePickerProps, MuiPickersUtilsProvider,} from '@material-ui/pickers';


const DatePicker: (props: KeyboardDatePickerProps) => ReactElement = (props) => {

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker data-testid="date-picker" {...props} />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
