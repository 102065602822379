import React, {ReactElement} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {currentEditEntryState, ownEntriesState} from './atoms';
import {loginTokenSate} from '../Login/atoms';
import {updateOwnEntries, updateOwnEntry} from './requests';
import EntryEditDialogComponent from '../../components/Entry/EntryEditDialogComponent';

const EntryUpdateDialog: () => ReactElement = () => {
  const [currentEditEntry, setCurrentEditEntry] = useRecoilState(currentEditEntryState);
  const [, setOwnEntries] = useRecoilState(ownEntriesState);
  const accessToken: string = useRecoilValue(loginTokenSate);

  const onClose = () => {
    setCurrentEditEntry(null);
  };

  const saveEntry: () => void = async () => {
    if (currentEditEntry) {
      const result = await updateOwnEntry(accessToken, currentEditEntry);
      if (result !== false) {
        updateOwnEntries(accessToken, setOwnEntries);
        onClose();
      }
    }
  };

  return <EntryEditDialogComponent onSave={saveEntry} onClose={onClose} open={currentEditEntry !== null} />;
};

export default EntryUpdateDialog;
