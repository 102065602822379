import React from 'react';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import EntryCreateForm from '../../../containers/Entry/EntryCreateForm';
import {EntryCreateDialogProps} from '../../../containers/Entry/EntryCreateDialog';

interface EntryCreateDialogComponentProps extends EntryCreateDialogProps {
  onSave: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionWrapper: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    action: {
      marginLeft: theme.spacing(1),
    }
  })
);

const EntryCreateDialogComponent: (props: EntryCreateDialogComponentProps) => JSX.Element = (props) => {
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <Dialog {...props}>
      <DialogTitle>{t('entry.create')}</DialogTitle>
      <DialogContent>
        <EntryCreateForm />
      </DialogContent>
      <DialogActions className={classes.actionWrapper}>
        <Button data-testid="entry-create-submit-button" color="secondary" variant="outlined" onClick={props.onSave}>{t('form.save')}</Button>
        <Button data-testid="entry-create-close-button" color="secondary" className={classes.action} onClick={props.onClose}>{t('form.close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EntryCreateDialogComponent;
