import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LngDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en/translation.json';
import translationDE from './locales/de/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
};

i18n
  .use(initReactI18next)
  .use(LngDetector)
  .init({
    resources,
    lng: 'de', //default language
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });


export default i18n;
