import React from 'react';
import {useRecoilValue} from 'recoil';
import {ownPermissions} from './atoms';
import {UserPermission} from './types';

interface RestrictedContentProps {
  children: JSX.Element,
  permission: UserPermission,
}

const RestrictedContent: (props: RestrictedContentProps) => JSX.Element = ({children, permission}) => {
  const permissions = useRecoilValue(ownPermissions);
  if (permissions.indexOf(permission) !== -1) {
    return children;
  }
  return <></>;
};

export default RestrictedContent;
