import {atom, selector} from 'recoil';
import {EntryModel, EntryModelWithShooter, FullEntryModelWithShooter} from '../../types';
import {emptyEntryModel} from '../../helper';
import moment from 'moment';
import {fullShooterSelector} from '../Shooter/atoms';

const emptyEntries: EntryModel[] = [];

export const allEntriesState = atom<EntryModelWithShooter[]>({
  key: 'allEntries',
  default: [],
});

export const allEntryMap = selector({
  key: 'allEntryMapSelector',
  get: ({get}) => {
    const allEntries = get(allEntriesState);
    const entryMap = new Map<number, EntryModelWithShooter>();
    for (const entry of allEntries) {
      entryMap.set(entry.id, entry);
    }
    return entryMap;
  },
});

export const ownEntriesState = atom({
  key: 'ownEntries',
  default: emptyEntries,
});

export const ownEntryMap = selector({
  key: 'ownEntryMapSelector',
  get: ({get}) => {
    const ownEntries = get(ownEntriesState);
    const entryMap = new Map<number, EntryModel>();
    for (const entry of ownEntries) {
      entryMap.set(entry.id, entry);
    }
    return entryMap;
  },
});

export const createEntryDraftState = atom<EntryModel>({
  key: 'createEntryDraft',
  default: {
    ...emptyEntryModel,
    date: moment().format('YYYY-MM-DD'),
  },
});

export const currentEditEntryState = atom<EntryModel|null>({
  key: 'currentEditEntry',
  default: null,
});

export const fullEntriesWithShooterMap = selector<Map<number, FullEntryModelWithShooter>>({
  key: 'fullEntryWithShooterMapSelector',
  get: ({get}) => {
    const shooters = get(fullShooterSelector);
    const entries = get(allEntriesState);
    const entryMap = new Map<number, FullEntryModelWithShooter>();
    for (const entry of entries) {
      const shooter = shooters.get(entry.shooter);
      if (shooter !== undefined) {
        const fullEntry: FullEntryModelWithShooter = {
          ...entry,
          shooter: shooter,
        };
        entryMap.set(entry.id, fullEntry);
      }
    }
    return entryMap;
  },
});

export const fullEntriesWithShooter = selector<FullEntryModelWithShooter[]>({
  key: 'fullEntryWithShooterSelector',
  get: ({get}) => {
    const shooters = get(fullShooterSelector);
    const entries = get(allEntriesState);
    const fullEntries = [];
    for (const entry of entries) {
      const shooter = shooters.get(entry.shooter);
      if (shooter !== undefined) {
        const fullEntry: FullEntryModelWithShooter = {
          ...entry,
          shooter: shooter,
        };
        fullEntries.push(fullEntry);
      }
    }
    return fullEntries;
  },
});
