import React, {useEffect, useState} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import XLSX from 'xlsx';
import {saveAs} from 'file-saver';
import {allEntriesState, fullEntriesWithShooter} from './atoms';
import {loginTokenSate} from '../Login/atoms';
import {getAllEntries} from './requests';
import {
  Button,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import DatePicker from '../../components/Picker/DatePicker';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {userMapSelector} from '../User/atoms';

interface ProcessedData {
  [a: number]: {
    training: {
      [c: string]: number,
    },
    competition: {
      [c: string]: number,
    },
    trainer: {
      [c: string]: string[],
    },
  },
}

const ExportPage: () => JSX.Element = () => {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [, setEntries] = useRecoilState(allEntriesState);
  const entries = useRecoilValue(fullEntriesWithShooter);
  const [excel, setExcel] = useState('');
  const users = useRecoilValue(userMapSelector);
  const accessToken = useRecoilValue(loginTokenSate);
  // eslint-disable-next-line
  const [entriesPerUser, setEntriesPerUser] = useState<ProcessedData>({});
  const {t} = useTranslation();

  useEffect(() => {
    setExcel(generateExcel());
  }, [entriesPerUser]);

  const convertEncoding: (s: string) => ArrayBuffer = (s) => {
    const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    const view = new Uint8Array(buf);  //create uint8array as viewer
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;
  };

  const generateExcel = () => {
    const dates = [];
    for (const entry of entries) {
      if (dates.indexOf(entry.date) === -1 && !(fromDate > entry.date || toDate < entry.date)) {
        dates.push(entry.date);
      }
    }
    const sortedDates = dates.sort();
    const data = [[t('name'), `${t('training')}/${t('competition')}`, t('total'), ...sortedDates]];
    for (const user of users.values()) {
      if (!entriesPerUser[user.id]) {
        continue;
      }
      const name = `${user.first_name} ${user.last_name}`;
      const trainingData = entriesPerUser[user.id].training;
      const competitionData = entriesPerUser[user.id].competition;
      const trainerData = entriesPerUser[user.id].trainer;
      let totalTraining = 0;
      let totalCompetition = 0;
      Object.keys(trainingData).forEach((key) => {
        totalTraining += trainingData[key];
      });
      Object.keys(competitionData).forEach((key) => {
        totalCompetition += competitionData[key];
      });
      const trainingRow = [name, t('training'), totalTraining.toString(), ...sortedDates.map((d) => trainingData[d] ? trainingData[d].toString() : '')];
      const competitionRow = ['', t('competition'), totalCompetition.toString(), ...sortedDates.map((d) => competitionData[d] ? competitionData[d].toString() : '')];
      const trainerRow = ['', t('trainer'), '', ...sortedDates.map((d) => trainerData[d] ? trainerData[d].join('/') : '')];
      data.push(trainingRow);
      data.push(competitionRow);
      data.push(trainerRow);
    }
    const cols = [];
    for (let i = 0; i < data[0].length; i++) {
      cols.push({name: XLSX.utils.encode_col(i), key: i});
    }
    const wb = XLSX.utils.book_new();
    wb.Props = {
      Title: `Shooting Export ${fromDate} to ${toDate}`,
      Author: window.location.host
    };
    wb.SheetNames.push(t('export'));
    wb.Sheets[wb.SheetNames[0]] = XLSX.utils.aoa_to_sheet(data);
    return XLSX.write(wb, {bookType: 'xlsx', type: 'binary'});
  };

  useEffect(() => {
    // eslint-disable-next-line
    const data: ProcessedData = {};
    for (const entry of entries) {
      const trainingTime = entry.duration;
      if (fromDate > entry.date || toDate < entry.date) {
        continue;
      }
      if (data[entry.shooter.user.id] === undefined) {
        data[entry.shooter.user.id] = {
          training: {},
          competition: {},
          trainer: {},
        };
      }
      if (data[entry.shooter.user.id].competition[entry.date] === undefined) {
        data[entry.shooter.user.id].competition[entry.date] = 0;
        data[entry.shooter.user.id].training[entry.date] = 0;
        data[entry.shooter.user.id].trainer[entry.date] = [];
      }
      if (entry.is_competition) {
        data[entry.shooter.user.id].competition[entry.date] += trainingTime;
      } else {
        data[entry.shooter.user.id].training[entry.date] += trainingTime;
      }
      if (data[entry.shooter.user.id].trainer[entry.date].indexOf(entry.trainer) === -1) {
        data[entry.shooter.user.id].trainer[entry.date].push(entry.trainer);
      }
    }
    setEntriesPerUser(data);
  }, [setEntriesPerUser, fromDate, toDate, entries]);

  useEffect(() => {
    if (accessToken.length === 0) {
      setEntries([]);
      return;
    }
    (async () => {
      const e = await getAllEntries(accessToken);
      setEntries(e);
    })();
  }, [setEntries, accessToken]);

  return (
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <DatePicker
                data-testid="entry-form-date-picker"
                value={fromDate}
                label={t('date_from')}
                format="YYYY-MM-DD"
                disableFuture={true}
                disableToolbar={true}
                onChange={(date) => setFromDate(moment(date).format('YYYY-MM-DD'))}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <DatePicker
                data-testid="entry-form-date-picker"
                value={toDate}
                label={t('date_to')}
                format="YYYY-MM-DD"
                disableFuture={true}
                disableToolbar={true}
                onChange={(date) => setToDate(moment(date).format('YYYY-MM-DD'))}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('entry_overview.shooter')}</TableCell>
                  <TableCell>{t('training_time')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(entriesPerUser).map((userId) => {
                  let total = 0;
                  Object.keys(entriesPerUser[Number(userId)].training).forEach((index) => {
                    total += entriesPerUser[Number(userId)].training[index];
                  });
                  Object.keys(entriesPerUser[Number(userId)].competition).forEach((index) => {
                    total += entriesPerUser[Number(userId)].competition[index];
                  });
                  const user = users.get(Number(userId));

                  return (
                    <TableRow key={userId}>
                      <TableCell>{`${user?.first_name} ${user?.last_name}`}</TableCell>
                      <TableCell>{total}min</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary"
            onClick={() => saveAs(new Blob([convertEncoding(excel)], {type: 'application/octet-stream'}), 'export.xlsx')}>
            {t('export')}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ExportPage;
