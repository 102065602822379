import React, {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {Drawer, List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import {MenuItem} from './types';

interface MenuProps {
  open: boolean,
  onClose: () => void,
  menuItems: MenuItem[],
}

const Menu: (props: MenuProps) => ReactElement = (props) => {
  const history = useHistory();
  const {t} = useTranslation();
  const {onClose, open, menuItems} = props;

  const navigateTo: (url: string) => void = (url) => {
    history.push(url);
    onClose();
  };

  const getItemOnClick: (item: MenuItem) => () => void = (item) => {
    if (item.onClick !== undefined) {
      return item.onClick;
    }
    return () => navigateTo(item.destination ?? '#');
  };

  return (
    <Drawer data-testid="menu-drawer" anchor="left" open={open} onClose={onClose}>
      <div>
        <List>
          <ListItem button onClick={() => navigateTo('/')}>
            <ListItemIcon>
              <ListIcon/>
            </ListItemIcon>
            <ListItemText primary={t('menu.overview')}/>
          </ListItem>
          <ListItem button onClick={() => navigateTo('/user')}>
            <ListItemIcon>
              <AccountBoxIcon/>
            </ListItemIcon>
            <ListItemText primary={t('menu.user')}/>
          </ListItem>
          {menuItems.map((item) => (
            <ListItem key={item.key} button onClick={getItemOnClick(item)}>
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={t(item.textKey)}/>
            </ListItem>
          ))}
        </List>
      </div>
    </Drawer>
  );
};

export default Menu;
