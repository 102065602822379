import React from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Grid, TextField} from '@material-ui/core';

interface LoginFormProps {
  username: string,
  password: string,
  onSubmit: () => void,
  setUsername: React.Dispatch<React.SetStateAction<string>>,
  setPassword: React.Dispatch<React.SetStateAction<string>>,
}

const LoginForm: (props: LoginFormProps) => React.ReactElement = ({
  username,
  password,
  onSubmit,
  setUsername,
  setPassword
}) => {
  const {t} = useTranslation();

  const handleSubmitKeyPress: (evt: React.KeyboardEvent<HTMLFormElement>) => void = (evt) => {
    if (evt.key === 'Enter') {
      onSubmit();
    }
  };

  return (
    <form onSubmit={onSubmit} onKeyPress={handleSubmitKeyPress} data-testid="login-form">
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <TextField
            id="login-username"
            label={t('username')}
            data-testid="login-form-username"
            required
            fullWidth
            variant="outlined"
            value={username}
            onChange={(evt) => setUsername(evt.currentTarget.value)}/>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="login-password"
            label={t('password')}
            required
            fullWidth
            variant="outlined"
            value={password}
            type="password"
            onChange={(evt) => setPassword(evt.currentTarget.value)}/>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item xs={6} md={4} lg={3}>
              <Button
                onClick={onSubmit}
                fullWidth
                data-testid="login-form-submit"
                variant="contained"
                color="primary">{t('login')}</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginForm;
