import React, {ReactElement, useState} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';
import {isLoggedInSelect} from '../Login/selectors';
import {loginTokenSate} from '../Login/atoms';
import {useLocalstorageState} from 'rooks';
import HeaderComponent from './HeaderComponent';
import {ownPermissions} from '../Permission/atoms';
import {UserPermission} from '../Permission/types';
import {MenuItem} from '../../components/Menu/types';
import {SHOOTER_ADD_ENTRY, SHOOTER_ADD_ENTRY_SELF, SHOOTER_VIEW_ENTRY} from '../Permission/permissions';
import ListIcon from '@material-ui/icons/List';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';

const Header: () => ReactElement = () => {
  const isLoggedIn = useRecoilValue(isLoggedInSelect);
  const [, setAccessToken] = useRecoilState(loginTokenSate);
  const [, , removeRefreshToken] = useLocalstorageState('refresh-token', '');
  const [menuOpen, setMenuOpen] = useState(false);
  const [entryCreateDialogOpen, setEntryCreateDialogOpen] = useState(false);
  const permissions = useRecoilValue(ownPermissions);

  const logOut: () => void = () => {
    removeRefreshToken();
    setAccessToken('');
  };

  const hasPermission: (permission: UserPermission) => boolean = (permission) => {
    return permissions.indexOf(permission) !== -1;
  };

  const additionalMenuItems: () => MenuItem[] = () => {
    const items: MenuItem[] = [];
    if (hasPermission(SHOOTER_VIEW_ENTRY)) {
      items.push({
        textKey: 'menu_item.trainer_overview',
        destination: '/trainer/overview',
        icon: <ListIcon />,
        key: 'menu-item-trainer',
      });
      items.push({
        textKey: 'menu_item.export',
        destination: '/export',
        icon: <GetAppIcon />,
        key: 'menu-item-export',
      });
    }
    if (hasPermission(SHOOTER_ADD_ENTRY) || hasPermission(SHOOTER_ADD_ENTRY_SELF)) {
      items.push({
        textKey: 'menu.add_entry',
        onClick: () => setEntryCreateDialogOpen(true),
        icon: <AddIcon />,
        key: 'menu-icon-add-entry',
      });
    }
    return items;
  };

  return <HeaderComponent
    additionalItems={additionalMenuItems()}
    isLoggedIn={isLoggedIn}
    menuOpen={menuOpen}
    entryCreateDialogOpen={entryCreateDialogOpen}
    setEntryCreateDialogOpen={setEntryCreateDialogOpen}
    setMenuOpen={setMenuOpen}
    logOut={logOut}
  />;
};

export default Header;
