import {UserModel} from '../../types';
import {SetterOrUpdater} from 'recoil';
import {basicAuthorizedJsonGetRequest, basicAuthorizedJsonPutRequest} from '../../helper';
import {PasswordChangePayload} from './types';
import {Message} from '../Message/types';
import moment from 'moment';
import React from 'react';

// Fetch the User of the logged in User and save it to the state.
export const updateOwnUserState: (accessToken: string, setOwnUserState: SetterOrUpdater<UserModel>) => void = async (accessToken, setOwnUserState) => {
  const response = await basicAuthorizedJsonGetRequest('/users/me/', accessToken);
  // TODO: Handle errors.
  if (response.ok) {
    const user: UserModel = await response.json();
    setOwnUserState(user);
  }
};

export const updateOwnUserRequest: (accessToken: string, setOwnUserState: SetterOrUpdater<UserModel>, user: string) => void = async (accessToken, setOwnUserState, user) => {
  const response = await basicAuthorizedJsonPutRequest('/users/me/', accessToken, user);
  // TODO: Handle errors.
  if (response.ok) {
    const newUser: UserModel = await response.json();
    setOwnUserState(newUser);
  }
};

export const changePasswordRequest: (accessToken: string, messages: Message[], setMessages: React.Dispatch<React.SetStateAction<Message[]>>, payload: PasswordChangePayload) => void = async (accessToken, messages, setMessages, payload) => {
  const response = await basicAuthorizedJsonPutRequest('/users/me/password/', accessToken, JSON.stringify(payload));
  if (response.ok) {
    const newMessage: Message = {
      message: 'messages.success.password_change',
      validUntil: moment().unix() * 1000 + 4000,
      severity: 'success',
    };
    setMessages([...messages, newMessage]);
  } else {
    // TODO: Use more meaningful message.
    const newMessage: Message = {
      message: 'errors.password_change.fail',
      validUntil: moment().unix() * 1000 + 4000,
      severity: 'error',
    };
    setMessages([...messages, newMessage]);
  }
};

export const fetchUsers: (accessToken: string) => Promise<UserModel[]> = async (accessToken) => {
  const response = await basicAuthorizedJsonGetRequest('/users/', accessToken);
  if (!response.ok) {
    // TODO: Handle the error correctly.
    return [];
  }
  const shooters: UserModel[] = await response.json();
  return shooters;
};
