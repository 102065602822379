import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto';
import {BrowserRouter} from 'react-router-dom';
import {RecoilRoot} from 'recoil';
import {createInstance, MatomoProvider} from '@datapunt/matomo-tracker-react';

const instance = createInstance({
  urlBase: 'https://analytics.chriswolf.ch',
  siteId: Number(process.env.REACT_APP_MATOMO_SITE_ID),
  disabled: process.env.NODE_ENV !== 'production',
  linkTracking: true,
  configurations: {
    disableCookies: true,
    setSecureCookie: true,
  },
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <RecoilRoot>
        <MatomoProvider value={instance}>
          <App/>
        </MatomoProvider>
      </RecoilRoot>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
