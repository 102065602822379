import React from 'react';
import {
  AppBar,
  Button,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Typography
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Menu from '../../components/Menu';
import EntryCreateDialog from '../Entry/EntryCreateDialog';
import EntryUpdateDialog from '../Entry/EntryUpdateDialog';
import {MenuItem} from '../../components/Menu/types';

interface HeaderComponentProps {
  isLoggedIn: boolean,
  menuOpen: boolean,
  entryCreateDialogOpen: boolean,
  setEntryCreateDialogOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>,
  logOut: () => void,
  additionalItems: MenuItem[],
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
    flexEnd: {
      justifyContent: 'flex-end',
    },
    branding: {
      textDecoration: 'none',
      color: theme.palette.primary.contrastText,
    }
  })
);

const HeaderComponent: (props: HeaderComponentProps) => JSX.Element = ({
  isLoggedIn,
  setEntryCreateDialogOpen,
  logOut,
  setMenuOpen,
  menuOpen,
  entryCreateDialogOpen,
  additionalItems,
}) => {
  const classes = useStyles();
  const {t} = useTranslation();

  const renderAuthItems = () => {
    if (isLoggedIn) {
      return (
        <div className={`${classes.flexContainer} ${classes.flexEnd}`}>
          <Button color="inherit" onClick={logOut}>
            {t('menu.logout')}
          </Button>
        </div>
      );
    }
    return <></>;
  };

  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <Grid container justifyContent="space-between">
            <Grid item xs={8} lg={2}>
              <div className={classes.flexContainer}>
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setMenuOpen(!menuOpen)}>
                  <MenuIcon/>
                </IconButton>
                <Link to="/" className={classes.branding}>
                  <Typography variant="h6">
                    {t('site.title')}
                  </Typography>
                </Link>
              </div>
            </Grid>
            <Grid item xs={4}>
              {renderAuthItems()}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Menu menuItems={additionalItems} open={menuOpen} onClose={() => setMenuOpen(false)}/>
      <EntryCreateDialog open={entryCreateDialogOpen} onClose={() => setEntryCreateDialogOpen(false)}/>
      <EntryUpdateDialog/>
    </>
  );
};

export default HeaderComponent;
