import React, {lazy, ReactElement, Suspense, useEffect, useState} from 'react';
import {Route, Switch} from 'react-router';
import AuthenticatedContentContainer from './containers/Login/AuthenticatedContentContainer';
import {
  Container,
  createStyles,
  createTheme,
  CssBaseline,
  makeStyles,
  MuiThemeProvider,
  Theme
} from '@material-ui/core';
import {useRecoilState, useRecoilValue} from 'recoil';
import {blueGrey, lightBlue} from '@material-ui/core/colors';
import {loginTokenSate} from './containers/Login/atoms';
import {ownUserState} from './containers/User/atoms';
import {disciplineState} from './containers/Discipline/atoms';
import {ownEntriesState} from './containers/Entry/atoms';
import {updateOwnUserState} from './containers/User/requests';
import {updateDisciplineState} from './containers/Discipline/requests';
import {updateOwnEntries} from './containers/Entry/requests';
import Header from './containers/Header/Header';
import {useLocalstorageState} from 'rooks';
import './i18n';
import Permission from './containers/Permission';
import LoadingIndicator from './components/LoadingIndicator';
import Messages from './containers/Message';
import {useMatomo} from '@datapunt/matomo-tracker-react';
import {useHistory} from 'react-router-dom';
import RestrictedContent from './containers/Permission/RestrictedContent';
import {AUTH_VIEW_USER, SHOOTER_VIEW_SHOOTER} from './containers/Permission/permissions';
import ExportPage from './containers/Entry/ExportPage';

const EntryPage = lazy(() => import('./containers/Entry/EntryPage'));
const AllEntryPage = lazy(() => import('./containers/Entry/AllEntryPage'));
const OwnEntryPage =  lazy(() => import('./containers/Entry/OwnEntryPage'));
const OwnUserInfo =  lazy(() => import('./containers/User/OwnUserInfo'));
const OwnUserEditPage =  lazy(() => import('./containers/User/OwnUserEditPage'));
const ShooterData = lazy(() => import('./containers/Shooter/ShooterData'));
const UserData = lazy(() => import('./containers/User/UserData'));

const lightTheme = createTheme({
  palette: {
    primary: blueGrey,
    secondary: lightBlue,
    type: 'light',
  }
});

const darkTheme = createTheme({
  palette: {
    primary: blueGrey,
    secondary: lightBlue,
    type: 'dark',
  },
});

function App(): ReactElement {
  // Check for the User's preferred theme mode.
  const defaultThemeMode = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  const [themeMode] = useLocalstorageState('theme-mode', defaultThemeMode);
  const [activeTheme, setActiveTheme]: [Theme, React.Dispatch<React.SetStateAction<Theme>>] = useState({...darkTheme});
  const accessToken = useRecoilValue(loginTokenSate);
  const [, setOwnUser] = useRecoilState(ownUserState);
  const [, setDisciplines] = useRecoilState(disciplineState);
  const [, setOwnEntries] = useRecoilState(ownEntriesState);

  useEffect(() => {
    if (themeMode) {
      if (themeMode === 'light') {
        setActiveTheme({...lightTheme});
      } else {
        setActiveTheme({...darkTheme});
      }
    }
  }, [themeMode]);

  useEffect(() => {
    if (accessToken && accessToken.length > 0) {
      updateOwnUserState(accessToken, setOwnUser);
      updateDisciplineState(accessToken, setDisciplines);
      updateOwnEntries(accessToken, setOwnEntries);
    }
  }, [accessToken]);

  return (
    <div className="App" data-testid="app-root">
      <MuiThemeProvider theme={activeTheme}>
        <Content/>
      </MuiThemeProvider>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      marginTop: theme.spacing(4),
    },
  })
);

const Content: () => ReactElement = () => {
  const classes = useStyles();
  const {trackPageView} = useMatomo();
  const history = useHistory();

  useEffect(() => {
    trackPageView({
      documentTitle: window.document.title,
      href: window.location.href,
    });
  }, [history.location.pathname]);

  return (
    <>
      <CssBaseline/>
      <Header/>
      <Permission />
      <Suspense fallback={<LoadingIndicator />}>
        <RestrictedContent permission={SHOOTER_VIEW_SHOOTER}>
          <ShooterData />
        </RestrictedContent>
        <RestrictedContent permission={AUTH_VIEW_USER}>
          <UserData />
        </RestrictedContent>
      </Suspense>
      <Container className={classes.content}>
        <AuthenticatedContentContainer>
          <Switch>
            <Suspense fallback={<LoadingIndicator />}>
              <Route path="/" exact component={OwnEntryPage} />
              <Route path="/export" exact component={ExportPage} />
              <Route path="/entry/:entryId" exact component={EntryPage} />
              <Route path="/user" exact component={OwnUserInfo} />
              <Route path="/user/edit" exact component={OwnUserEditPage} />
              <Route path="/trainer/overview" exact component={AllEntryPage} />
            </Suspense>
          </Switch>
        </AuthenticatedContentContainer>
      </Container>
      <Messages />
    </>
  );
};

export default App;
