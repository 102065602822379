import React from 'react';
import {CircularProgress, Container, createStyles, makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    center: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(3),
    }
  })
);

const LoadingIndicator: () => JSX.Element = () => {
  const classes = useStyles();

  return (
    <Container className={classes.center}>
      <CircularProgress/>
    </Container>
  );
};

export default LoadingIndicator;
